import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HCOHome from './components/hco/home/HCOHome';
import Home from './components/Home';
import { CssBaseline, ThemeProvider } from '@mui/material';
import appTheme from './theme/appTheme';
import HCOAdminHome from './components/hcoadmin/HCOAdminHome';
import SupportD177 from './components/support/supportD177/SupportD177';
import { ScrollToTop } from './components/common/ScrollToTop';

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="hco" element={<HCOHome />} />
        <Route path="D177" element={<SupportD177 />} />
        <Route path="hcoadmin" element={<HCOAdminHome />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;

import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import HCOHomeParagraphTitle from './HCOHomeParagraphTitle';
import HCOHomeParagraphDescription, {
  TextSize,
} from './HCOHomeParagraphDescription';
import { HCOHomeParagraphDivider } from './HCOHomeParagraphDivider';
import HCOHomeParagraphSubtitle from './HCOHomeParagraphSubtitle';
import Typography from '@mui/material/Typography';
import appTheme from '../../../theme/appTheme';
import React from 'react';

export function HCOHomeRules() {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <HCOHomeParagraphTitle text={t('hco.competition.rules')} />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDescription text={t('hco.competition.rule1')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule2')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule3')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule4')} />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDivider />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphSubtitle text={t('hco.competition.mastersCategory')} />
        <HCOHomeParagraphDescription
          text={t('hco.competition.ruleM1')}
          textSize={TextSize.SMALL}
        />
        <HCOHomeParagraphDescription
          text={t('hco.competition.ruleM2')}
          textSize={TextSize.SMALL}
        />
        <HCOHomeParagraphDescription
          text={t('hco.competition.ruleM3')}
          textSize={TextSize.SMALL}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphSubtitle text={t('hco.competition.openCategory')} />
        <HCOHomeParagraphDescription
          text={t('hco.competition.ruleO1')}
          textSize={TextSize.SMALL}
        />
        <HCOHomeParagraphDescription
          text={t('hco.competition.ruleO2')}
          textSize={TextSize.SMALL}
        />
        <HCOHomeParagraphDescription
          text={t('hco.competition.ruleO3')}
          textSize={TextSize.SMALL}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDivider />
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography sx={{ color: appTheme.palette.text.secondary }}>
          {' '}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDescription text={t('hco.competition.rule5')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule51')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule6')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule7')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule8')} />
        <HCOHomeParagraphDescription text={t('hco.competition.rule9')} />
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { Stack, Typography } from '@mui/material';

export default function BancInfo() {
  return (
    <Stack sx={{ pl: 2, pr: 2 }}>
      <Typography
        fontFamily={'inter-light'}
        sx={{ color: 'white', fontSize: 'larger' }}
      >
        Club Sportiv Alternative Timișoara
      </Typography>
      <Typography sx={{ color: 'white' }}>CIF: 15156297</Typography>
      <Typography sx={{ color: 'white' }}>
        RO65 RNCB 0255 1146 1034 0001
      </Typography>
      <Typography sx={{ color: 'white' }}>
        Timisoara, B-dul Eroilor Nr. 7C, Ap. 11
      </Typography>
      <Typography sx={{ color: 'white' }}>office@alternativetm.ro</Typography>
    </Stack>
  );
}

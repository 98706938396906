import firestore from './firestore';
import { collection, doc } from 'firebase/firestore/lite';
import isDev from '../util/environment';

const envSuffix = isDev() ? 'Dev' : '';
const hcoEditions = '/HCOEditions';

const hcoEditionPath = (year: string) => hcoEditions + '/' + year;

const hcoRegistrationsPath = (editionId: string) =>
  hcoEditionPath(editionId) + '/registrations' + envSuffix;

export const hcoEditionsCollection = collection(firestore, hcoEditions);

export const hcoEditionDoc = (year: string) =>
  doc(firestore, hcoEditionPath(year));

export const hcoRegistrationsCollection = (editionId: string) =>
  collection(firestore, hcoRegistrationsPath(editionId));

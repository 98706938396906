import React from 'react';
import { Grid } from '@mui/material';

import { ReactComponent as DecathlonLogo } from './sponsor_logos/decathlon2024.svg';
import { ReactComponent as AlpinExpeLogo } from './sponsor_logos/alpinexpe.svg';
import { ReactComponent as BlokxLogo } from './sponsor_logos/blokx.svg';
import { ReactComponent as FraeLogo } from './sponsor_logos/frae.svg';
import { ReactComponent as HimalayaLogo } from './sponsor_logos/himalaya.svg';
import { ReactComponent as MagicLogo } from './sponsor_logos/magic.svg';
import { ReactComponent as OcunLogo } from './sponsor_logos/ocun.svg';
import { ReactComponent as PrimariaLogo } from './sponsor_logos/primaria.svg';

import { ReactComponent as RedisLogo } from './sponsor_logos/redis.svg';
import { ReactComponent as DirtyOrganicsLogo } from './sponsor_logos/dirty-organics.svg';
import { ReactComponent as RockLogo } from './sponsor_logos/rock-n-snow.svg';
import { ReactComponent as SalvamontLogo } from './sponsor_logos/salvamont.svg';
import { ReactComponent as TechClimbLogo } from './sponsor_logos/techclimb.svg';
import { ReactComponent as RecasLogo } from './sponsor_logos/recas.svg';
import { ReactComponent as TrustInvestLogo } from './sponsor_logos/trust_invest.svg';
import appTheme from '../../../theme/appTheme';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function HCOSponsors() {
  const { t } = useTranslation();
  const columnSpacing = () => {
    return window.innerWidth / 202;
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography>{t('hco.sponsors.principal')}</Typography>
          </Grid>
          <Grid item>
            <DecathlonLogo fill={appTheme.palette.text.secondary} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 3, md: columnSpacing() }}
          direction={'row'}
          alignItems={'center'}
        >
          <Grid item xs="auto">
            <AlpinExpeLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <OcunLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          {/*          <Grid item xs="auto">
            <HimalayaLogo fill={appTheme.palette.text.secondary} />
          </Grid>*/}
          <Grid item xs="auto">
            <BlokxLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <RockLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <DirtyOrganicsLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <TechClimbLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <RedisLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <RecasLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <FraeLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <TrustInvestLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <PrimariaLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <SalvamontLogo fill={appTheme.palette.text.secondary} />
          </Grid>
          <Grid item xs="auto">
            <MagicLogo fill={appTheme.palette.text.secondary} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

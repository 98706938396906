import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SupportCard } from './SupportCard';

export function HomeSupportParagraph() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const open3percentLink = () => {
    const redirect3AlternativeLink =
      'https://www.redirectioneaza.ro/alternative';
    window.open(redirect3AlternativeLink, '_blank');
  };

  const openD177Link = () => {
    const d177Link = '/D177';
    navigate(d177Link);
  };

  return (
    <Grid container spacing={2} id="support" alignItems="stretch">
      <Grid item xs={12} md={12}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: 'bold', pt: 2 }}
        >
          {t('supportTitle')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <SupportCard
          title={t('supportD177.title')}
          subtitle={t('supportD177.subtitle')}
          actionText={t('supportD177.actionDetails')}
          onClick={openD177Link}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ height: '100%' }}>
        <SupportCard
          title={t('support3%.title')}
          subtitle={t('support3%.subtitle')}
          actionText={t('support3%.actionRedirect')}
          onClick={open3percentLink}
        />
      </Grid>
    </Grid>
  );
}

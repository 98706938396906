import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  categories,
  HCORegistration,
  sex,
} from '../../../data/hco/model/HCORegistration';
import appTheme from '../../../theme/appTheme';
import { useTranslation } from 'react-i18next';

interface HCORegistrationsProps {
  registrations: HCORegistration[] | undefined;
}

export default function HCORegistrations({
  registrations,
}: HCORegistrationsProps) {
  const { t } = useTranslation();
  const sortedRegistrations = registrations?.sort(
    (objA, objB) =>
      Date.parse(objA.termsAcceptedDate!.toString()) -
      Date.parse(objB.termsAcceptedDate!.toString())
  );

  const rowTypography = (text: any, confirmed: any) =>
    confirmed ? (
      <Typography color={appTheme.palette.text.secondary}>{text}</Typography>
    ) : (
      <Typography color="#5A5A5A" fontStyle={'italic'}>
        {text}
      </Typography>
    );

  return (
    <Grid container spacing={0}>
      {sortedRegistrations ? (
        <TableContainer
          component={Paper}
          sx={{
            background: appTheme.palette.background.default,
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th" width="10%">
                  #
                </TableCell>
                <TableCell component="th">
                  <Typography>{t('hco.participant.name')}</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography>{t('hco.participant.category')}</Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography>{t('hco.participant.confirmStatus')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRegistrations.map((user, idx) => (
                <TableRow key={idx}>
                  <TableCell width="10%">
                    {rowTypography(idx + 1, user.confirmed)}
                  </TableCell>
                  <TableCell>
                    {rowTypography(
                      user.firstName + ' ' + user.lastName,
                      user.confirmed
                    )}
                  </TableCell>
                  <TableCell>
                    {rowTypography(
                      categories.find((cat) => user.category === cat.value)
                        ?.label +
                        ' ' +
                        sex.find((sex) => user.sex === sex.value)?.label,
                      user.confirmed
                    )}
                  </TableCell>
                  <TableCell>
                    {rowTypography(
                      t(
                        user.confirmed
                          ? 'hco.participant.confirmed'
                          : 'hco.participant.unconfirmed'
                      ),
                      user.confirmed
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ''
      )}
    </Grid>
  );
}

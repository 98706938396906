import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore/lite";

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
const firebaseConfig = {
  apiKey: "AIzaSyC6yHQVPyFAbTIU4idLRBiDfuk0l1Q13yM",
  authDomain: "herculaneclimbingopen.firebaseapp.com",
  databaseURL: "https://herculaneclimbingopen.firebaseio.com",
  projectId: "herculaneclimbingopen",
  storageBucket: "herculaneclimbingopen.appspot.com",
  messagingSenderId: "42595659789",
  appId: "1:42595659789:web:0c46ec6ea068ae186ee544"
}
const firebaseApp = initializeApp(firebaseConfig)
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LfNW-4gAAAAAJJKweAj8R2Qlvk23yMWQEVRYETQ'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const firestore = getFirestore(firebaseApp)

export default firestore
import { Box, Button, CardActions, ThemeProvider } from '@mui/material';
import { boxTheme } from '../../theme/boxTheme';
import appTheme from '../../theme/appTheme';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

interface SupportCardProps {
  title: string;
  subtitle: string;
  actionText: string;
  onClick: () => void;
}

export function SupportCard({
  title,
  subtitle,
  actionText,
  onClick,
}: SupportCardProps) {
  return (
    <ThemeProvider theme={boxTheme}>
      <Box
        sx={{
          backgroundColor: appTheme.palette.primary.main,
          borderRadius: '15px',
          p: 4,
          minHeight: '220px',
          display: 'flex', // Enables Flexbox
          justifyContent: 'center', // Centers content horizontally
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'inter-light',
                color: 'black',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'pre-wrap',
                fontWeight: 'bold',
                fontFamily: 'inter-light',
                color: 'black',
              }}
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <CardActions>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  minHeight: '50px',
                }}
                onClick={onClick}
              >
                {actionText}
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

import React, { useEffect } from 'react';
import {
  categoryText,
  formatRegisteredDate,
  HCORegistration,
} from '../../data/hco/model/HCORegistration';
import { useTranslation } from 'react-i18next';
import { Box, Modal, Typography } from '@mui/material';
import { Close, Done } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  background: 'gray',
};

export interface HCOUserDetailsPopupProps {
  open: boolean;
  user?: HCORegistration;
  handleClose?: () => void;
}

export function HCORegistrationDetailsPopup({
  open,
  user,
  handleClose,
}: HCOUserDetailsPopupProps) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log('modal users state open: ' + open);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ pb: 3 }}
        >
          {user?.firstName + ' ' + user?.lastName}
        </Typography>
        <Typography>
          <b>{t('hco.participant.confirmStatus')}</b> :
          {user?.confirmed === true ? (
            <Done sx={{ color: 'green' }} />
          ) : (
            <Close sx={{ color: 'red' }} />
          )}
        </Typography>
        <Typography>
          <b>{t('hco.participant.registrationDate')}</b>
          {': ' + formatRegisteredDate(user)}
        </Typography>
        <Typography>
          <b>{t('hco.participant.paymentType')}</b>
          {': ' + user?.paymentType}
        </Typography>
        <Typography>
          <b>{t('hco.registration.category')}</b>
          {': ' + categoryText(user)}
        </Typography>
        <Typography>
          <b>{t('hco.registration.email')}</b>
          {': ' + user?.email}
        </Typography>
        <Typography>
          <b>{t('hco.registration.phone')}</b>
          {': ' + user?.phone}
        </Typography>
        <Typography>
          <b>{t('hco.registration.club')}</b>
          {': ' + user?.club}
        </Typography>
        <Typography>
          <b>{t('hco.registration.tshirtSize')}</b>
          {': ' + user?.tshirtSize}
        </Typography>
        <Typography>
          <b>{t('hco.registration.country')}</b>
          {': ' + user?.country}
        </Typography>
        <Typography>
          <b>{t('hco.registration.city')}</b>
          {': ' + user?.city}
        </Typography>
        <Typography>
          <b>{t('hco.participant.address')}</b>
          {': ' + user?.address}
        </Typography>
        <Typography>
          <b>{t('hco.registration.birthDate')}</b>
          {': ' + user?.birthDate}
        </Typography>
      </Box>
    </Modal>
  );
}

import React from 'react';
import Typography from '@mui/material/Typography';
import appTheme from '../../../theme/appTheme';
import Box from '@mui/material/Box';

export enum TextSize {
  SMALL,
  MEDIUM,
  LARGE,
}

interface HCOParagraphDescription {
  text: String;
  textSize?: TextSize;
  textColor?: String;
  noSpacing?: Boolean;
}

export default function HCOHomeParagraphDescription({
  text,
  textSize,
  textColor,
  noSpacing,
}: HCOParagraphDescription) {
  const fontSize = () => {
    switch (textSize) {
      case TextSize.SMALL:
        return { md: '1rem', xs: '1rem' };
      case TextSize.MEDIUM:
        return { md: '1.3rem', xs: '1.2rem' };
      case TextSize.LARGE:
        return { md: '2rem', xs: '1.5rem' };
      case undefined:
        return { md: '2rem', xs: '1.5rem' };
    }
  };

  const pb = noSpacing ? {} : { md: 5, xs: 3 };

  const sxDescription = {
    color: textColor ? textColor.toString() : appTheme.palette.text.secondary,
    fontFamily: 'inter-light',
    fontSize: fontSize(),
    pb: pb,
  };

  return (
    <Typography sx={sxDescription} style={{ whiteSpace: 'pre-line' }}>
      {text}
    </Typography>
  );
}

export const descriptionSpacingBottom = <Box sx={{ pb: { md: 5, xs: 3 } }} />;

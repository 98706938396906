import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { BackArrowHeader } from '../../BackArrowHeader';
import { useTranslation } from 'react-i18next';
import HCOHomeParagraphDescription, {
  TextSize,
} from '../../hco/home/HCOHomeParagraphDescription';
import { Button, Card, CardMedia } from '@mui/material';
import appTheme from '../../../theme/appTheme';
import { HCOHomeParagraphDivider } from '../../hco/home/HCOHomeParagraphDivider';
import { FilesD177 } from './FilesD177';
import { FileButton } from './FileButton';
import { D177BadgeInfo } from './D177BadgeInfo';
import Box from '@mui/material/Box';
import Footer from '../../Footer';

const imgPath = `/images/sponsorD177.jpeg`;

export default function SupportD177() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" sx={{ paddingBottom: 20 }}>
      <Box sx={{ paddingTop: 3 }}>
        <BackArrowHeader />
      </Box>

      <Grid container paddingBottom={0} spacing={2}>
        <Grid item xs={12} md={12} sx={{ marginTop: 5, marginBottom: 5 }}>
          <Grid container paddingBottom={0} spacing={4}>
            <Grid item xs={12} md={7}>
              <Typography
                sx={{
                  fontSize: { md: '8.5rem', xs: '4rem' },
                  lineHeight: '1.2',
                  fontWeight: '300',
                  color: 'white',
                  fontFamily: 'inter-light',
                  maxWidth: '100%',
                }}
              >
                {t('supportD177.title') + ' ' + new Date().getFullYear()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card sx={{ maxWidth: '100%' }}>
                <CardMedia component="img" image={imgPath} />
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <HCOHomeParagraphDescription
            text={t('supportD177.about.title')}
            textColor={'white'}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <HCOHomeParagraphDescription
            text={t('supportD177.about.description')}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container paddingBottom={0} spacing={2}>
          <Grid item xs={12} md={3}>
            <FileButton
              text={t('supportD177.about.contract_button')}
              file={FilesD177.CONTRACT}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FileButton
              text={t('supportD177.about.declaration_button')}
              file={FilesD177.FORM}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button variant="outlined">
              <a
                style={{
                  textDecoration: 'none',
                  color: appTheme.palette.primary.main,
                }}
                target="_blank"
                rel="noreferrer"
                href={
                  'https://www.anaf.ro/anaf/internet/ANAF/asistenta_contribuabili/declararea_obligatiilor_fiscale/toate_formularele/#177n'
                }
              >
                {t('supportD177.about.anaf_page')}
              </a>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <HCOHomeParagraphDivider />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDescription
          text={t('supportD177.howto.title')}
          textColor={'white'}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDescription
          text={t('supportD177.howto.description_intro')}
          textSize={TextSize.MEDIUM}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container paddingBottom={0} spacing={6}>
          <Grid item xs={12} md={4}>
            <D177BadgeInfo
              text={t('supportD177.howto.description1')}
              badgeText={'1'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <D177BadgeInfo
              text={t('supportD177.howto.description2')}
              badgeText={'2'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <D177BadgeInfo
              text={t('supportD177.howto.description3')}
              badgeText={'3'}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  );
}

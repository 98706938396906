import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import appTheme from '../../../../theme/appTheme';
import { HCORegistrationsCount } from '../../../../data/hco/model/HCORegistrationsCount';
import { useTranslation } from 'react-i18next';

interface HCORegistrationNumbersProps {
  userCount: HCORegistrationsCount | undefined;
}

export default function HCORegistrationsNumber({
  userCount,
}: HCORegistrationNumbersProps) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minWidth: '350px',
        padding: 1.5,
      }}
    >
      <Stack direction="column" sx={{ paddingRight: 3, paddingLeft: 3 }}>
        <Typography
          fontFamily="inter-light"
          variant="h6"
          color={appTheme.palette.text.secondary}
        >
          {t('hco.registration.registeredCount')}
        </Typography>
        <Typography variant="h1">{userCount?.total()}</Typography>
      </Stack>

      <Divider sx={{ margin: 1 }} />

      <Stack direction="row" sx={{ paddingLeft: 3, paddingTop: 2 }}>
        <Typography variant="h2">{userCount?.master}</Typography>
        <Typography
          fontFamily="inter-light"
          variant="h6"
          color={appTheme.palette.text.secondary}
          sx={{ paddingLeft: 1, paddingRight: 2 }}
        >
          Masters
        </Typography>
        <Typography fontFamily="inter-light" variant="h2">
          {userCount?.open}
        </Typography>
        <Typography
          variant="h6"
          color={appTheme.palette.text.secondary}
          sx={{ paddingLeft: 1 }}
        >
          Open
        </Typography>
      </Stack>
    </Box>
  );
}

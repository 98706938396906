export class HCORegistrationsCount {
  master: number;
  open: number;

  constructor(master: number, open: number) {
    this.master = master;
    this.open = open;
  }

  total() {
    return this.master + this.open;
  }
}

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Stack,
} from '@mui/material';
import CSA_Logo from '../resources/logo-csa-orizontal.svg';
import HCO_Logo from '../resources/HCO-land.png';
import HM_Image from '../resources/hercules-land.png';
import Horia_Image from '../resources/horia-land.png';
import AlergamPeVerticala_Image from '../resources/copii-apv-land.jpeg';
import Tura_Ischgl_Image from '../resources/ture/skiischgl.png';
import Tura_Parang_Image from '../resources/ture/boulderparang.png';
import Tura_Crovuri_Image from '../resources/ture/crovuri.png';
import Tura_Mai_Image from '../resources/ture/turamai.png';
import Footer from './Footer';
import appTheme from '../theme/appTheme';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Menu from './menudrawer/Menu';
import { HomeSupportParagraph } from './support/HomeSupportParagraph';

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    const elementById = document.getElementById(
      window.location.hash.substring(1)
    );
    if (elementById) {
      // Scroll to the element with the ID that matches the hash
      elementById.scrollIntoView();
    }
  }, [window.location.hash]); // This effect depends on the URL hash

  return (
    <Container maxWidth="xl">
      <Box>
        <Menu />
        <Grid container spacing={2} mt={12} id="home">
          <Grid item xs={12} md={12} sx={{ paddingBottom: 20 }}>
            <Container>
              <img
                src={CSA_Logo}
                width="100%"
                title={t('CSA.name')}
                alt={t('CSA.name')}
              />
            </Container>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ color: appTheme.palette.text.secondary }}>
              {t('CSA.since')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ color: appTheme.palette.text.secondary }}>
              {t('CSA.where')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: appTheme.palette.text.secondary,
                fontSize: 'xx-large',
              }}
            >
              {t('CSA.motto')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider color={'#414141'} sx={{ marginTop: 30 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} columns={14}>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.alpineClimbing')}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.rockClimbing')}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.ski')}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.expeditions')}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.trailRunning')}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.biking')}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography sx={{ color: '#5A5A5A' }}>
                  {t('sports.voley')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider color={'#5A5A5A'} sx={{ marginTop: 25 }} />
          </Grid>
        </Grid>
        <Grid container spacing={2} id="projects">
          <Grid item xs={12} md={6}>
            <Card sx={{ maxWidth: 1000 }}>
              <CardActionArea component={Link} to="/hco">
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography gutterBottom variant="h6" component="div">
                    <ArrowUpwardIcon style={{ transform: 'rotate(45deg)' }} />{' '}
                    {t('hco.title')}
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  image={HCO_Logo}
                  title={t('hco.title')}
                  alt={t('hco.title')}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ maxWidth: 1000 }}>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://hercules-maraton.ro/"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography gutterBottom variant="h6" component="div">
                    <ArrowUpwardIcon style={{ transform: 'rotate(45deg)' }} />{' '}
                    {t('hercules.title')}
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  image={HM_Image}
                  title={t('hercules.title')}
                  alt={t('hercules.title')}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ maxWidth: 1000 }}>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/events/843398260518829"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography gutterBottom variant="h6" component="div">
                    <ArrowUpwardIcon style={{ transform: 'rotate(45deg)' }} />{' '}
                    {t('alergamPeVerticala.title')}
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  image={AlergamPeVerticala_Image}
                  title={t('alergamPeVerticala.title')}
                  alt={t('alergamPeVerticala.title')}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sx={{ align: 'middle' }}>
            <Card sx={{ maxWidth: 1000 }}>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://horiacolibasanuhimalaya.ro/ro/alpinistul-horia-colibasanu-incepe-in-aceasta-vara-o-noua-expeditie-in-lantul-muntos-karakorum-din-pakistan-incearca-o-ascensiune-pe-al-zecelea-sau-varf-de-8000-de-metri-broad-peak-8051-m/"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography gutterBottom variant="h6" component="div">
                    <ArrowUpwardIcon style={{ transform: 'rotate(45deg)' }} />{' '}
                    {t('horia.title')}
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  image={Horia_Image}
                  title={t('horia.title')}
                  alt={t('horia.title')}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider color={'#414141'} sx={{ marginTop: 25 }} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant={'h5'}
              sx={{ color: 'white', fontWeight: 'bold' }}
            >
              {t('CSA.mission.label')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography
              sx={{
                color: appTheme.palette.text.secondary,
                fontSize: 'x-large',
              }}
            >
              {t('CSA.mission.text')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider color={'#414141'} sx={{ marginTop: 25 }} />
          </Grid>
        </Grid>

        <HomeSupportParagraph />

        <Grid container spacing={2} id="hikes">
          <Grid item xs={12} md={12}>
            <Divider color={'#414141'} sx={{ marginTop: 25 }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/media/set/?set=a.5141673829186291&type=3"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography variant={'subtitle1'} component="div">
                    Ski la Ischgl, Martie 2022
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: appTheme.palette.text.secondary,
                        fontSize: 'x-small',
                      }}
                    >
                      {t('trips.writtenBy')}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 'x-small',
                        paddingLeft: 0.5,
                      }}
                    >
                      MARDALE
                    </Typography>
                  </Stack>
                </CardContent>
                <CardMedia
                  component="img"
                  height="365"
                  image={Tura_Ischgl_Image}
                  alt="Ski la Ischgl, Martie 2022"
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/media/set/?set=a.5190139524339721&type=3"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography variant={'subtitle1'} component="div">
                    Bouldering în Parâng, 2021
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: appTheme.palette.text.secondary,
                        fontSize: 'x-small',
                      }}
                    >
                      {t('trips.writtenBy')}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 'x-small',
                        paddingLeft: 0.5,
                      }}
                    >
                      MARDALE
                    </Typography>
                  </Stack>
                </CardContent>
                <CardMedia
                  component="img"
                  height="365"
                  image={Tura_Parang_Image}
                  alt="Bouldering în Parâng, 2021"
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/photo?fbid=892209841726575&set=a.836629487284611"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography variant={'subtitle1'} component="div">
                    Tura lunii mai
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: appTheme.palette.text.secondary,
                        fontSize: 'x-small',
                      }}
                    >
                      {t('trips.writtenBy')}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 'x-small',
                        paddingLeft: 0.5,
                      }}
                    >
                      MARDALE
                    </Typography>
                  </Stack>
                </CardContent>
                <CardMedia
                  component="img"
                  height="365"
                  image={Tura_Mai_Image}
                  alt="Tura lunii mai"
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/media/set/?vanity=alternativetm.ro&set=a.5226659294021077"
              >
                <CardContent sx={{ backgroundColor: 'black' }}>
                  <Typography variant={'subtitle1'} component="div">
                    Crovuri-Țăsnei, Aprilie 2022
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: appTheme.palette.text.secondary,
                        fontSize: 'x-small',
                      }}
                    >
                      {t('trips.writtenBy')}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 'x-small',
                        paddingLeft: 0.5,
                      }}
                    >
                      MARDALE
                    </Typography>
                  </Stack>
                </CardContent>
                <CardMedia
                  component="img"
                  height="365"
                  image={Tura_Crovuri_Image}
                  alt="Crovuri-Țăsnei, Aprilie 2022"
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </Container>
  );
}

export default Home;

import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ThemeProvider,
} from '@mui/material';
import appTheme from '../../theme/appTheme';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import HCOAdmin from './HCOAdmin';
import { Visibility, VisibilityOff } from '@mui/icons-material';

class FirebaseUser {
  email: String;

  constructor(email: String) {
    this.email = email;
  }
}

interface AuthData {
  email?: string;
  password?: string;
}

export default function HCOAdminHome() {
  const [authData, setAuthData] = useState<AuthData>({});
  const [errorState, setErrorState] = useState<AuthData>({});
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | undefined>();
  const auth = getAuth();

  const signIn = () => {
    signInWithEmailAndPassword(auth, authData.email!, authData.password!)
      .then((userCredential) => {
        setFirebaseUser(new FirebaseUser(String(userCredential.user.email)));
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrorState({ email: errorMessage });
      });
  };

  const handleAuthEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let authCopy = { ...authData };
    authCopy.email = event.target.value;
    console.log('auth data: ', JSON.stringify(authCopy));
    setAuthData(authCopy);

    let errCopy = { ...errorState };
    errCopy.email = undefined;
    setErrorState(errCopy);
  };

  const handlePwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let authCopy = { ...authData };
    authCopy.password = event.target.value;
    console.log('auth data: ', JSON.stringify(authCopy));
    setAuthData(authCopy);

    let errCopy = { ...errorState };
    errCopy.password = undefined;
    setErrorState(errCopy);
  };

  const handleLogin = () => {
    console.log('firebase login with data: ', JSON.stringify(authData));
    if (authData === undefined) {
      setErrorState({ email: 'Invalid', password: 'Invalid' });
      return;
    }
    if (authData?.email === undefined || authData.email === '') {
      setErrorState({ email: 'Invalid', password: '' });
      return;
    }
    if (authData?.password === undefined || authData.password === '') {
      setErrorState({ email: '', password: 'Invalid' });
      return;
    }
    signIn();
  };

  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const authForm = () => {
    return (
      <Stack direction="column" sx={{ p: 5 }}>
        <TextField
          required
          error={errorState?.email !== undefined}
          helperText={errorState?.email}
          placeholder="Email"
          label="Email"
          id="email"
          variant="filled"
          onChange={handleAuthEmailChange}
          size="small"
          sx={{ width: '100%', pb: 2 }}
        />

        <TextField
          required
          error={errorState?.password !== undefined}
          helperText={errorState?.password}
          placeholder="Password"
          label="Password"
          id="email"
          variant="filled"
          value={authData.password}
          onChange={handlePwdChange}
          size="small"
          sx={{ width: '100%', pb: 4 }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button variant="contained" onClick={handleLogin}>
          Login
        </Button>
      </Stack>
    );
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Grid container sx={{ height: '100hv', p: 5 }}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {firebaseUser ? <HCOAdmin /> : authForm()}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { HCORegistration } from '../../data/hco/model/HCORegistration';
import { useTranslation } from 'react-i18next';
import { HCOStatistics } from './HCOStatistics';
import { HCOAdminParticipants } from './HCOAdminParticipants';
import { HCOParticipantsExcelTable } from './HCOParticipantsExcelTable';
import { hcoRegistrationsRepository } from '../../data/hco/HCORegistrationsRepository';
import { currentEditionLocal } from '../../data/hco/model/HCOEdition';

export default function HCOAdmin() {
  const [registrations, setRegistrations] = useState<
    HCORegistration[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  let currentRegistrationRepository =
    hcoRegistrationsRepository(currentEditionLocal);

  const memoizedRepository = useMemo(
    () => currentRegistrationRepository,
    [currentRegistrationRepository]
  );

  const updateRegistrations = useCallback(() => {
    setIsLoading(true);
    memoizedRepository
      ?.fetchRegistrations()
      .then((userList) => {
        console.log('registrations updated! ', userList);
        setRegistrations(
          userList.sort(
            (objA, objB) =>
              Date.parse(objA.termsAcceptedDate!.toString()) -
              Date.parse(objB.termsAcceptedDate!.toString())
          )
        );
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error('user request failed!!!', ex);
        setIsLoading(false);
      });
  }, [memoizedRepository]);

  useEffect(() => {
    updateRegistrations();
  }, []);

  const handleConfirmedCheckedChange = useCallback(
    (confirmed: boolean, user: HCORegistration) => {
      setIsLoading(true);
      currentRegistrationRepository
        ?.updateRegistrationConfirmation(user.id!!, confirmed)
        .then(() => {
          console.log('hco user updated: ', user);
          updateRegistrations();
        })
        .catch((ex) => {
          console.log('hco user updating failed: ', user, ex);
          alert('Updating user failed');
          setIsLoading(false);
        });
    },
    [currentRegistrationRepository, updateRegistrations]
  );

  const handleDeleteUser = useCallback(
    (user: HCORegistration) => {
      setIsLoading(true);
      currentRegistrationRepository
        ?.deleteRegistration(user)
        .then(() => {
          console.log('user deletion succeeded ' + user.email);
          updateRegistrations();
        })
        .catch((ex) => {
          console.error('user deletion failed!!!', ex);
          setIsLoading(false);
        });
    },
    [currentRegistrationRepository, updateRegistrations]
  );

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label={t('hco.admin.tabParticipants')} />
        <Tab label={t('hco.admin.tabStatistics')} />
        <Tab label={t('hco.admin.tabExcelExport')} />
      </Tabs>

      {tabValue === 0 ? (
        <HCOAdminParticipants
          registrations={registrations}
          isLoading={isLoading}
          handleDeleteRegistration={handleDeleteUser}
          handleConfirmedCheckedChange={handleConfirmedCheckedChange}
        />
      ) : tabValue === 1 ? (
        <HCOStatistics registrations={registrations} />
      ) : (
        <HCOParticipantsExcelTable registrations={registrations} />
      )}
    </Box>
  );
}

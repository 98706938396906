import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  HCORegistrationDetailsPopup,
  HCOUserDetailsPopupProps,
} from './HCORegistrationDetailsPopup';
import appTheme from '../../theme/appTheme';
import {
  categoryText,
  formatRegisteredDate,
  HCORegistration,
} from '../../data/hco/model/HCORegistration';
import { useTranslation } from 'react-i18next';
import HCORegistrationDeleteButtonDialog from './HCORegistrationDeleteButtonDialog';

interface HCOAdminParticipantsProps {
  registrations?: HCORegistration[];
  isLoading: boolean;
  handleConfirmedCheckedChange: (
    confirmed: boolean,
    user: HCORegistration
  ) => void;
  handleDeleteRegistration: (user: HCORegistration) => void;
}

export function HCOAdminParticipants({
  registrations,
  isLoading,
  handleConfirmedCheckedChange,
  handleDeleteRegistration,
}: HCOAdminParticipantsProps) {
  const { t } = useTranslation();

  const confirmedCheckBox = (user: HCORegistration) => {
    const confirmed = user.confirmed === true;

    return (
      <Stack direction="row" alignItems="center" sx={{ pt: 1, ml: -1.5 }}>
        <Checkbox
          checked={confirmed}
          onChange={(e) => {
            e.stopPropagation();
            handleConfirmedCheckedChange(!confirmed, user);
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleConfirmedCheckedChange(!confirmed, user);
          }}
        />
        <Typography sx={{ cursor: 'pointer' }}>
          {t(
            confirmed
              ? 'hco.participant.confirmed'
              : 'hco.participant.unconfirmed'
          )}
          &nbsp;
        </Typography>
      </Stack>
    );
  };

  const deleteButton = (user: HCORegistration) => {
    return (
      <HCORegistrationDeleteButtonDialog
        user={user}
        handleDeleteUser={handleDeleteRegistration}
      />
    );
  };

  const [detailsPopupProps, setDetailsPopupProps] =
    useState<HCOUserDetailsPopupProps>({ open: false });

  const handleDetailsPopupOpen = (user: HCORegistration) =>
    setDetailsPopupProps({
      open: true,
      user: user,
    });

  const handleDetailsPopupClose = () => setDetailsPopupProps({ open: false });

  const rowTypography = (text: any, confirmed: any) =>
    confirmed ? (
      <Typography color={appTheme.palette.text.secondary}>{text}</Typography>
    ) : (
      <Typography color="#5A5A5A" fontStyle={'italic'}>
        {text}
      </Typography>
    );

  return (
    <Box position="relative">
      {' '}
      {/* This Box is used to position the overlay correctly */}
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <HCORegistrationDetailsPopup
            open={detailsPopupProps.open}
            user={detailsPopupProps.user}
            handleClose={handleDetailsPopupClose}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {registrations ? (
            <TableContainer
              component={Paper}
              sx={{
                marginTop: 10,
                background: appTheme.palette.background.default,
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">#</TableCell>
                    <TableCell component="th">
                      <Typography>{t('hco.participant.name')}</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>{t('hco.participant.category')}</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>{t('hco.registration.email')}</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>{t('hco.registration.club')}</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>
                        {t('hco.registration.tshirtSize')}
                      </Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>
                        {t('hco.participant.registrationDate')}
                      </Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>
                        {t('hco.participant.paymentType')}
                      </Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography>
                        {t('hco.participant.confirmStatus')}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registrations.map((user, idx) => (
                    <TableRow
                      key={user.id!.toString()}
                      onClick={() => {
                        handleDetailsPopupOpen(user);
                      }}
                    >
                      <TableCell width="10%">
                        {rowTypography(idx + 1, user.confirmed)}
                      </TableCell>
                      <TableCell>
                        {rowTypography(
                          user.firstName + ' ' + user.lastName,
                          user.confirmed
                        )}
                      </TableCell>
                      <TableCell>
                        {rowTypography(categoryText(user), user.confirmed)}
                      </TableCell>
                      <TableCell>
                        {rowTypography(user.email, user.confirmed)}
                      </TableCell>
                      <TableCell>
                        {rowTypography(user.club, user.confirmed)}
                      </TableCell>
                      <TableCell>
                        {rowTypography(user.tshirtSize, user.confirmed)}
                      </TableCell>
                      <TableCell>
                        {rowTypography(
                          formatRegisteredDate(user),
                          user.confirmed
                        )}
                      </TableCell>
                      <TableCell>
                        {rowTypography(user.paymentType, user.confirmed)}
                      </TableCell>
                      <TableCell>{confirmedCheckBox(user)}</TableCell>
                      <TableCell>{deleteButton(user)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0, 0, 0, 0.8)" // semi-transparent white background
          sx={{ pointerEvents: 'none' }} // makes it non-clickable
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

import React from 'react';
import Typography from '@mui/material/Typography';

interface HCOParagraphSubtitle {
  text: String;
}

export default function HCOHomeParagraphSubtitle({
  text,
}: HCOParagraphSubtitle) {
  const sx = {
    color: 'white',
    pb: 1,
    fontFamily: 'inter',
    fontSize: { md: '1rem', xs: '0.8rem' },
  };
  return <Typography sx={sx}>{text}</Typography>;
}

import { Button } from '@mui/material';
import { FilesD177 } from './FilesD177';
import React from 'react';

interface FileButtonProps {
  text: String;
  file: FilesD177;
}

export function FileButton({ text, file }: FileButtonProps) {
  return (
    <Button variant="contained">
      <a
        style={{
          textDecoration: 'none',
          color: 'black',
        }}
        href={file}
        download
      >
        {text}
      </a>
    </Button>
  );
}

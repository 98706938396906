import * as React from 'react';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

type MenuItemProps = {
  to: string;
  text: string;
  isActive?: boolean;
  openInNewWindow?: boolean;
};

const Dot = () => (
  <Box
    sx={{
      marginTop: 1,
      marginBottom: 0.5,
      height: 8,
      width: 8,
      backgroundColor: 'primary.main',
      borderRadius: '50%',
      display: 'inline-block',
    }}
  />
);

// TODO: refactor using blank)
const MenuItem = ({ to, text, isActive, openInNewWindow }: MenuItemProps) => {
  return (
    <Box
      sx={{
        mx: { xs: 1, sm: 3 },
        my: 0.5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {openInNewWindow ? (
        <a href={to} target="_blank" rel="noreferrer" style={{  textDecoration: 'none'}}>
                <Typography sx={{ color: 'white' }}>{text}</Typography>
        </a>
      ) : (
        <>
          <Link
            underline="none"
            sx={{
              color: 'common.white',
              fontWeight: isActive ? 700 : 400,
            }}
            href={to}
          >
            {text}
          </Link>
        </>
      )}
      {isActive && <Dot />}
    </Box>
  );
};

export default MenuItem;

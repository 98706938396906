import React, { useCallback, useEffect, useState } from 'react';
import { hcoRegistrationsRepository } from '../../../data/hco/HCORegistrationsRepository';
import { HCOEdition } from '../../../data/hco/model/HCOEdition';
import Grid from '@mui/material/Grid';
import HCORegisterCard from '../registration/HCORegisterCard';
import HCOSponsors from '../sponsors/HCOSponsors';
import {
  HCOHomeParagraphDivider,
  paragraphMarginTop,
} from './HCOHomeParagraphDivider';
import HCOHomeParagraphTitle from './HCOHomeParagraphTitle';
import HCOHomeParagraphDescription, {
  descriptionSpacingBottom,
} from './HCOHomeParagraphDescription';
import HCORegistrations from '../registration/HCORegistrations';
import { useTranslation } from 'react-i18next';
import { HCORegistration } from '../../../data/hco/model/HCORegistration';
import { HCORegistrationsCount } from '../../../data/hco/model/HCORegistrationsCount';

class HCOHomeRegistrationState {
  registrations: HCORegistration[];

  constructor(users: HCORegistration[]) {
    this.registrations = users;
  }

  getRegistrationsCount() {
    let open = 0;
    let master = 0;

    this.registrations.forEach((user) => {
      console.log('users count user: ' + user);
      if (user.category === 'open') {
        open++;
      } else {
        master++;
      }
    });

    return new HCORegistrationsCount(master, open);
  }
}

interface HCOHomeRegistrationProps {
  currentEdition: HCOEdition;
}

export function HCOHomeRegistration({
  currentEdition,
}: HCOHomeRegistrationProps) {
  const [registrationState, setRegistrationState] = useState<
    HCOHomeRegistrationState | undefined
  >();
  const { t } = useTranslation();

  const updateRegistrations = useCallback(() => {
    if (currentEdition !== undefined) {
      hcoRegistrationsRepository(currentEdition)
        .fetchRegistrations()
        .then((users) => {
          setRegistrationState(new HCOHomeRegistrationState(users));
        })
        .catch((ex) => console.error('user count request failed!!!', ex));
    }
  }, [currentEdition]);

  useEffect(() => {
    updateRegistrations();
  }, [updateRegistrations]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pt: { md: 5, xs: 3 }, pb: { md: 5, xs: 3 } }}>
        {descriptionSpacingBottom}
        <HCORegisterCard
          edition={currentEdition ? currentEdition : currentEdition}
          registrationsCount={registrationState?.getRegistrationsCount()}
          updateRegistrationsCount={updateRegistrations}
        />
      </Grid>

      <Grid item xs={12} md={12} sx={paragraphMarginTop}>
        <HCOSponsors />
      </Grid>

      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDivider />
      </Grid>

      <Grid item xs={12} id="participants">
        <Grid container>
          <Grid item xs={12} md={12}>
            <HCOHomeParagraphTitle text={t('hco.participants.title')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <HCOHomeParagraphDescription text={t('hco.participants.hint1')} />
            <HCOHomeParagraphDescription text={t('hco.participants.hint2')} />
            {descriptionSpacingBottom}
          </Grid>
          <Grid item xs={12} md={12}>
            <HCORegistrations
              registrations={registrationState?.registrations}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

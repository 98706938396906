export const getOrdinalSuffix = (number: number | null): string => {
    if (number === null || number === undefined) return '';

    const j = number % 10;
    const k = number % 100;

    if (j === 1 && k !== 11) {
        return `st`;
    }
    if (j === 2 && k !== 12) {
        return `nd`;
    }
    if (j === 3 && k !== 13) {
        return `rd`;
    }
    return `th`;
};

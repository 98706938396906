import { Badge, Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

export function D177BadgeInfo(props: { text: string; badgeText: string }) {
  return (
    <Badge badgeContent={props.badgeText} color="primary">
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
            {props.text}
          </Typography>
        </CardContent>
      </Card>
    </Badge>
  );
}

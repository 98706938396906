import { Link } from '@mui/material';
import appTheme from '../../theme/appTheme';
import React from 'react';
// @ts-ignore
import { genericHashLink } from 'react-router-hash-link';

const MaterialHashLink = genericHashLink(Link);

export const MenuLink = (
  componentIdToScrollTo: String,
  label: String,
  highlight: boolean = false
) => {
  const path = '/hco#' + componentIdToScrollTo;
  let color = highlight
    ? appTheme.palette.primary
    : appTheme.palette.text.secondary;
  const sx = {
    paddingRight: 5,
    color: { color },
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
    },
  };
  return (
    <div style={{ maxWidth: '130px' }}>
      <MaterialHashLink smooth to={path} sx={sx} underline="none">
        {label}
      </MaterialHashLink>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import MenuItem from './/MenuItem';
import { Divider, Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CSA_Logo from '../../resources/logo-csa-orizontal.svg';
import { LanguageMenu } from './/LanguageMenu';

const drawerWidth = 240;
const navItems = [
  {
    id: 'home',
    to: '/#home',
  },
  {
    id: 'projects',
    to: '/#projects',
  },
  {
    id: 'support',
    to: '/#support',
  },
  {
    id: 'hikes',
    to: '/#hikes',
  },
  {
    id: 'shop',
    to: 'https://alternativetm.ro/shop/',
    openInNewWindow: true,
  },
  {
    id: 'historic',
    to: 'https://arhiva.alternativetm.ro/',
    openInNewWindow: true,
  },
];

const Menu = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeMenuItem, setActiveMenuItem] = useState('home');
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', paddingTop: 2 }}
    >
      <img
        src={CSA_Logo}
        width="60%"
        title={t('CSA.name')}
        alt={t('CSA.name')}
      />
      <Divider sx={{ pt: 2 }} />
      <Box sx={{ pt: 1 }}>
        {navItems.map(({ id, ...props }) => (
          <MenuItem
            key={id}
            isActive={id === activeMenuItem}
            text={t(`header.${id}`)}
            {...props}
          />
        ))}
      </Box>
    </Box>
  );

  // TODO: debounce
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let currentMenuItem;
    navItems.forEach(({ id }) => {
      let section: any = document.querySelector(`#${id}`);
      if (section && scrollPosition + 102 > section.offsetTop) {
        currentMenuItem = id;
      }
    });
    if (currentMenuItem && currentMenuItem !== activeMenuItem) {
      setActiveMenuItem(currentMenuItem);
    }
  }, [scrollPosition, activeMenuItem]);

  return (
    <Box>
      <AppBar
        component="nav"
        sx={{ pt: 1.25, pr: 4, background: 'black', boxShadow: 'none' }}
      >
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none', paddingLeft: 20 },
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <LanguageMenu />
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {navItems.map(({ id, ...props }) => (
            <MenuItem
              key={id}
              isActive={id === activeMenuItem}
              text={t(`header.${id}`)}
              {...props}
            />
          ))}
          {LanguageMenu()}
        </Box>
      </AppBar>

      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background: 'black',
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default Menu;

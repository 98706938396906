import React from 'react';
import { Divider } from '@mui/material';

export const paragraphMarginTop = {
  marginTop: { md: 12, xs: 10 },
  marginBottom: { md: 2, xs: 1 },
};

export function HCOHomeParagraphDivider() {
  return <Divider color={'#414141'} sx={paragraphMarginTop} />;
}

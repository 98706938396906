import { createTheme, LinkProps } from '@mui/material';

import { CustomLink } from './CustomLink';

const appTheme = createTheme({
  spacing: 8,
  components: {
    MuiLink: {
      defaultProps: {
        component: CustomLink,
      } as LinkProps,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFC506',
    },
    secondary: {
      main: '#424242',
    },
    background: {
      default: '#000000',
      paper: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: '#9E9E9E',
    },
    action: {
      active: '#ffffff',
    },
    info: {
      main: '#202020',
    },
    success: {
      main: '#FFC506',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
});

export default appTheme;
